const firebaseConfig = {
  apiKey: "AIzaSyC7TIbJNYRxjW82rpV3tNQBl4kCMPZQFtY",
  authDomain: "sendit-shopify-app.firebaseapp.com",
  databaseURL: "https://sendit-shopify-app.firebaseio.com",
  projectId: "sendit-shopify-app",
  storageBucket: "sendit-shopify-app.appspot.com",
  messagingSenderId: "768683161210",
  appId: "1:768683161210:web:bc3ddf5c25b9777e5cadc3",
  measurementId: "G-QY1V1PGKNZ"
};

export default firebaseConfig;