import React, { useState } from 'react';
import Client from 'shopify-buy';
import { getLocalStorage, setLocalStorage } from '../components/utils/storage';

const client = Client.buildClient({
  domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
  storefrontAccessToken: process.env.GATSBY_ACCESS_TOKEN,
});

const defaultState = {
  cart: {},
};

const CartContext = React.createContext(defaultState);
export default CartContext;

export function CartContextProvider({ children }) {
  const [checkout, setCheckout] = useState(
    JSON.parse(
      typeof window !== 'undefined' ? getLocalStorage('checkout') : null
    )
  );

  const [successfulOrder, setSuccessfulOrder] = useState(null);
  const checkoutId = checkout?.id;

  React.useEffect(() => {
    const getCheckout = async () => {
      if (checkoutId && typeof window !== 'undefined') {
        const fetchedCheckout = await client.checkout.fetch(checkoutId);
        if (fetchedCheckout?.completedAt) {
          localStorage.removeItem('checkout');
          setCheckout(null);
          setSuccessfulOrder(fetchedCheckout);
        } else {
          setCheckout(fetchedCheckout);
          setLocalStorage('checkout', JSON.stringify(fetchedCheckout));
        }
      }
    };

    getCheckout();
  }, [setCheckout, setSuccessfulOrder, checkoutId]);

  async function getProductById(productId) {
    const product = await client.product.fetch(productId);
    return product;
  }

  const updateLineItem = async ({ variantId, quantity, buy }) => {
    // if no checkout id, create a new checkout
    let newCheckout = checkout || (await client.checkout.create());

    // check to see if this variantId exists in storedCheckout
    const lineItemVariant = newCheckout.lineItems?.find(
      lineItem => lineItem.variant.id === variantId
    );

    if (lineItemVariant) {
      const newQuantity = lineItemVariant.quantity + quantity;

      if (newQuantity) {
        newCheckout = await client.checkout.updateLineItems(newCheckout.id, [
          {
            id: lineItemVariant.id,
            quantity: newQuantity,
          },
        ]);
      } else {
        newCheckout = await client.checkout.removeLineItems(newCheckout.id, [
          lineItemVariant.id,
        ]);
      }
    } else {
      newCheckout = await client.checkout.addLineItems(newCheckout.id, [
        {
          variantId,
          quantity,
        },
      ]);
    }

    setCheckout(newCheckout);
    setSuccessfulOrder(null);
    setLocalStorage('checkout', JSON.stringify(newCheckout));
    if (buy) {
      window.open(checkout.webUrl, '_blank');
    }
  };

  const removeLineItem = async lineItemId => {
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [
      lineItemId,
    ]);

    setCheckout(newCheckout);
  };

  const dismissSuccessfulOrder = () => {
    setSuccessfulOrder(null);
  };

  const updateLineItemAddres = async () => {
    const shippingAddress = {
      address1: 'Envio a sucursal-no editar',
      address2: 'Envio a sucursal-no editar',
      city: 'ciudad del a furia',
      company: null,
      country: 'United States',
      firstName: 'Bob',
      lastName: 'Norman',
      phone: '555-625-1199',
      province: 'Kentucky',
      zip: '40202',
    };
    const newCheckout = await client.checkout.updateShippingAddress(
      checkout.id,
      shippingAddress
    );

    setCheckout(newCheckout);
  };

  const createCheckout = async () => {
    const newCheckout = await client.checkout.create();
    setCheckout(newCheckout);
  }

  return (
    <CartContext.Provider
      value={{
        checkout,
        updateLineItem,
        removeLineItem,
        createCheckout,
        getProductById,
        successfulOrder,
        updateLineItemAddres,
        dismissSuccessfulOrder,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
