import React from 'react';
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import { FirestoreProvider } from '@react-firebase/firestore';
import { FirebaseAuthProvider } from '@react-firebase/auth';

import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

import { ProductContextProvider } from './src/context/ProductContext';
import { CartContextProvider } from './src/context/CartContext';
import { GlobalStyle } from './src/components/globalStyles';
import '@shopify/polaris/dist/styles.css';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import firebaseConfig from './firebase-config';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

firebase.initializeApp(firebaseConfig);
const stripePromise = loadStripe(
  'pk_live_51H2HYmCdJA85TDyggWN0Onzf4lP3ve0q60O12hAEkNognJT2wC4fusKZbs0iaN2Dtk1WznakYcOe9IJkLvsNRHZd00h5o2OzK4'
);

export const wrapRootElement = ({ element }) => (
  <AppProvider i18n={enTranslations}>
    <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
      <FirestoreProvider firebase={firebase}>
        <Elements stripe={stripePromise}>
          <ProductContextProvider>
          <Helmet>
        <script src={withPrefix('script.js')} type="text/javascript" />
           </Helmet>
            <CartContextProvider>{element}</CartContextProvider>
          </ProductContextProvider>
        </Elements>
      </FirestoreProvider>
    </FirebaseAuthProvider>
  </AppProvider>
);

export const wrapPageElement = ({ element }) => (
  <>
    <Elements stripe={stripePromise}>
      <GlobalStyle />
      {element}
    </Elements>
  </>
);
