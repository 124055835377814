// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-cart-sumary-js": () => import("./../src/pages/cartSumary.js" /* webpackChunkName: "component---src-pages-cart-sumary-js" */),
  "component---src-pages-compra-saldo-js": () => import("./../src/pages/compraSaldo.js" /* webpackChunkName: "component---src-pages-compra-saldo-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-modulo-recargas-js": () => import("./../src/pages/moduloRecargas.js" /* webpackChunkName: "component---src-pages-modulo-recargas-js" */),
  "component---src-pages-paga-saldo-js": () => import("./../src/pages/pagaSaldo.js" /* webpackChunkName: "component---src-pages-paga-saldo-js" */),
  "component---src-pages-perfil-js": () => import("./../src/pages/perfil.js" /* webpackChunkName: "component---src-pages-perfil-js" */),
  "component---src-pages-productos-js": () => import("./../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-pages-recargas-js": () => import("./../src/pages/recargas.js" /* webpackChunkName: "component---src-pages-recargas-js" */),
  "component---src-templates-products-templates-index-js": () => import("./../src/templates/productsTemplates/index.js" /* webpackChunkName: "component---src-templates-products-templates-index-js" */)
}

