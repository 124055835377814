import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import EnglishTrans from "../locales/en/Translations.json";
import SpanishTrans from "../locales/tw/Translations.json";
import firebase from "@firebase/app";
import { navigate } from "@reach/router";

const query = graphql`
  fragment ProductTitleFields on ShopifyProduct {
    handle
    priceRange {
      minVariantPrice {
        amount
      }
    }
  }
  {
    allShopifyProduct(filter: { tags: { ne: "promo" } }) {
      edges {
        node {
          ...ShopifyProductFields
          ...ProductTitleFields
        }
      }
    }
    allShopifyCollection(sort: { fields: title, order: ASC }) {
      edges {
        node {
          products {
            ...ShopifyProductFields
            ...ProductTitleFields
          }
          title
          description
          shopifyId
        }
      }
    }
  }
`;

const defaultState = {
  products: [],
};

const ProductContext = React.createContext(defaultState);
export default ProductContext;

export function ProductContextProvider({ children }) {
  //
  const [lang, setLang] = useState("es");

  const [translate, setTranslate] = useState(
    lang === "es" ? SpanishTrans : EnglishTrans
  );

  const getNewTranslate = (value) => {
    let tempTranslate = value === "es" ? SpanishTrans : EnglishTrans;
    setLang(value);
    setTranslate(tempTranslate);
  };
  //
  const [selectCountry, setSelectCountry] = useState("");
  const [usuario, setUsuario] = useState(null);
  const [permisos, setPermisos] = useState(null);
  const [selectedShop, setSelectedShop] = useState({});
  const [selectedCredit, setSelectedCredit] = useState({});
  const { allShopifyCollection, allShopifyProduct } = useStaticQuery(query);
  const excludedItems = ["El Salvador"];
  const countryList = allShopifyCollection.edges.filter(({ node }) => {
    return excludedItems.find((element) => element == node.title);
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setUsuario(user);
        return;
      }
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((res) => {
          setPermisos(res.claims);
          let col = res.claims.employee ? "employees" : "users";
          firebase
            .firestore()
            .collection(col)
            .where("uid", "==", user.uid)
            .get()
            .then((snapshot) => {
              if (!snapshot.docs[0]) return;
              let user = snapshot.docs[0].data();
              setUsuario(user);
              if (res.claims.admin) {
                navigate("/admin");
              } else {
                navigate("/perfil");
              }
            });
        });
    });
  }, []);

  return (
    <ProductContext.Provider
      value={{
        products: allShopifyProduct.edges.map(({ node }) => node),
        collections: allShopifyCollection.edges.map(({ node }) => node),
        countryList: countryList,
        setSelectCountry: setSelectCountry,
        selectCountry: selectCountry,
        usuario: usuario,
        setUsuario: setUsuario,
        setPermisos: setPermisos,
        permisos: permisos,
        selectedShop: selectedShop,
        selectedCredit: selectedCredit,
        setSelectedCredit: setSelectedCredit,
        setSelectedShop: setSelectedShop,
        currentLang: lang,
        setCurrentLang: getNewTranslate,
        currentTranslate: translate,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}
